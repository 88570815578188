<template>
  <div id="products">
    <h1>{{ $t('Products') }}</h1>

    <b-row>
      <b-col
        v-for="product in products"
        :key="product.id"
        class="mt-2"
        md="6"
        lg="3"
        xl="3"
        sm="12"
      >
        <b-card
          class="cardContent"
          no-body
        >
          <b-img
            class="card-img-top cursor-pointer w-100"
            :alt="`${product.name}-${product.id}`"
            fluid-grow
            :src="getImage(product.images)"
          />
          <div
            id="acortarPadre"
            class="m-2"
          >
            <div class="d-flex justify-content-between">
              <b-card-title
                id="acortar"
                class="mb-25 d-flex justify-content-between"
              >
                <h5 class="item-name">
                  {{ product.name }}
                </h5>
              </b-card-title>
              <feather-icon icon="ShoppingBagIcon" />
            </div>
            <b-card-text
              id="acortar"
              class="text-muted"
            >
              {{ product.description.length==0?$t('productWithoutDescription'):product.short_description }}
            </b-card-text>
            <h3 class="mt-2">
              {{ product.price }}€
            </h3>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BCardTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BImg,
    BCardTitle,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      products: null,

    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const options = {
        method: 'GET',
        headers: {
          Accept: 'application/json',

        },
      }

      const params = 'id,name,price,images,brand,description,short_description'
      const count = 10
      const apiKey = '92834e87c5e90c017d6a5386458404c1'
      const storeKey = '47b60bfc1c87d58d79277a69831f7c33'
      const request = `https://api.api2cart.com/v1.1/product.list.json?start=0&count=${count}&params=${params}&sort_by=id&sort_direction=asc&apiKey=${apiKey}&storeKey=${storeKey}`
      fetch(
        request,
        options,
      ).then(response => response.json())
        .then(response => {
          this.products = response.result.product
        })
        .catch(err => (console.log(err)))
    },
    getImage(data) {
      let path = '@/assets/images/backend/nocover.jpeg'
      if (data.length !== 0) {
        data.forEach(element => {
          path = element.http_path
        })
      }
      return path
    },
  },

}
</script>

<style lang="scss" scoped>
#products .actions {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: rgb(0 15 51 / 73%);
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

#products .cardContent img {
    height: 11vw;
    object-fit: cover;
    /*width: 100%;
        height:  100%;
        object-fit: cover;*/
}

#products .card-body {
    padding: 1rem;
}

#products .card-body h4 {
    font-size: 1rem !important;
}

#products .card-header {
    padding: 1rem;
}

#products .card-header h4 {
    font-size: 1rem !important;
}

#products .card-body .actions {
    display: -webkit-flex;
    display: flex;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 2.75rem;
    padding-right: 0.34375rem;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    /* justify-content: flex-end; */
    justify-content: space-between;
}

/*.folder {
      min-height: 150px;
      height: 300px;
      cursor: all-scroll;
    }*/
#products .dropContainer {
    border: 3px dashed;
}

/*.card-width {
      width: 200px;
    }*/
#products .list-group-item {
    transition: all 1s;
}

#products #acortar {
    overflow: hidden;
    text-overflow: ellipsis;
}

#products #acortarPadre {
    overflow: hidden;
    white-space: nowrap;
}
</style>
